export default {
    multiple({data, headers}) {
        return data.response
    },
    single({data, headers}) {
        return data.response
    },
    empty() {
        return []
    }
}